import { useQuery } from '@tanstack/react-query';

import { fetchSingleBeerStockList } from './request';

const useSingleBeerStockList = (beer: string) => {
    return useQuery({
        queryKey: ['useSingleBeerStockList', beer],
        queryFn: () => fetchSingleBeerStockList(beer),
        placeholderData: [],
    });
};

export default useSingleBeerStockList;
