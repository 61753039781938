import { useQuery } from '@tanstack/react-query';

import fetchBrands from './request';

import { Brand } from 'types';

const usePantryBrandsAll = () => {
    return useQuery<Brand[]>({
        queryKey: ['usePantryBrandsAll'],
        queryFn: fetchBrands,
        placeholderData: [],
        retryOnMount: true,
    });
}

export default usePantryBrandsAll;
