import { useQuery } from '@tanstack/react-query';

import fetchReceipts from './request';

const usePantryReceipts =  (page: number, searchTerm: string) => {
    return useQuery({
        queryKey: ['usePantryReceipts', page, searchTerm],
        queryFn: () => fetchReceipts(page, searchTerm),
        placeholderData: {
            "count": 0,
            "next": null,
            "previous": null,
            "results": [],
        },
    });
};

export default usePantryReceipts;