import { useQuery } from '@tanstack/react-query';

import api from 'services/api';
import { User } from 'types';

const fetchUserDetails = async () => {
    const url = '/v1/users/me';
    const { data } = await api.get<User>(url);
    return data;
}

const useUserDetails = () => {
    return useQuery({
        queryKey: ['useUserDetails'],
        queryFn: fetchUserDetails,
        refetchOnMount: true,
        refetchOnWindowFocus: 'always',
    });
};

export default useUserDetails;
