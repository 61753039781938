import api from 'services/api';
import { BeerColor } from 'types';

const fetchBeerColors = async () => {
    const url = '/pantry/beers/colors/';

    const { data } = await api.get<BeerColor[]>(url);
    return data;
}

export default fetchBeerColors;
