import { useQuery } from '@tanstack/react-query';

import api from 'services/api';
import { Storage } from 'types';

const fetchStorages = async () => {
    const url = '/pantry/storages/';
    const { data } = await api.get<Storage[]>(url);
    return data;
}

const usePantryStorages = () => {
    return useQuery({
        queryKey: ['usePantryStorages'],
        queryFn: fetchStorages,
        refetchOnMount: true,
        placeholderData: [],
        refetchOnWindowFocus: 'always',
    });
};

export default usePantryStorages;
