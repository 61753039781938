import { useQuery } from '@tanstack/react-query';

import fetchBeerColors from './request';

import { BeerColor } from 'types';

const useBeerColors = () => {
    return useQuery<BeerColor[]>({
        queryKey: ['useBeerColors'],
        queryFn: fetchBeerColors,
        placeholderData: [],
        retryOnMount: true,
    });
}

export default useBeerColors;
