import api from 'services/api';

const fetchBrandDetails = async (id: string | undefined) => {
    if (!id) return Promise.resolve(null);

    const { data } = await api.get(`/pantry/brands/${id}/`);
    return data;
}

export default fetchBrandDetails;
