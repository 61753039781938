import { useQuery } from '@tanstack/react-query';

import fetchPantryReceiptItems from './request';

const usePantryReceiptItems = (id: string | undefined) => {
    return useQuery({
        queryKey: ['usePantryReceiptItems', id],
        queryFn: () => fetchPantryReceiptItems(id),
        placeholderData: null,
    });
};

export default usePantryReceiptItems;
