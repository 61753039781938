import api from 'services/api';
import { BeerStyle } from 'types';

const fetchBeerStylesAll = async () => {
    const url = '/pantry/beers/styles/all/';

    const { data } = await api.get<BeerStyle[]>(url);
    return data;
}

export default fetchBeerStylesAll;
