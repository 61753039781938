import api from 'services/api';

import { PetsParams, PetsResponse } from './types';

const fetchPets = async (page: number, searchTerm: string = '') => {
    const currentPage = page || 1;
    let params: PetsParams = {
        page: currentPage,
    }

    if (searchTerm.length > 0) {
        params = {
            ...params,
            name: searchTerm,
        }
    }
    const url = '/pets/'
    const { data } = await api.get<PetsResponse>(url, { params });
    return data;
}

export default fetchPets;
