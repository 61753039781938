interface Config {
    frontendURL?: string;
    companyName?: string;
    companyURL?: string;
    apiURL?: string;
    environment?: string;
}

const config: Config = {
    frontendURL: process.env.REACT_APP_FE_URL || "https://makecodes.dev",
    companyName: process.env.REACT_APP_COMPANY_NAME || "Make Codes",
    companyURL: process.env.REACT_APP_COMPANY_URL || "https://makecodes.dev",
    apiURL: process.env.REACT_APP_API_URL || "https://be.makecodes.dev",
    environment: process.env.REACT_APP_ENVIRONMENT || "development",
};

export default config;
