import {
    IconCat,
    IconBowl,
} from "@tabler/icons-react";

const pets = {
    id: "PET",
    title: "Pets",
    type: "group",
    children: [
        {
            id: "food-journal",
            title: "Diário de Ração",
            type: "item",
            url: "/pets/food/journal",
            icon: IconBowl,
            breadcrumbs: false,
        },
        {
            id: "pets",
            title: "Pets",
            type: "item",
            url: "/pets",
            icon: IconCat,
            breadcrumbs: false,
        },
    ],
}

export default pets;