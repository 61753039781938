import api from 'services/api';

import { BeerContainerType } from 'types';

const fetchBeerContainerTypes = async () => {
    const url = '/pantry/beers/container-types/';
    const { data } = await api.get<BeerContainerType[]>(url);
    return data;
}

export default fetchBeerContainerTypes;
