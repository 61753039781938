import api from 'services/api';

const fetchProductByBarcode = async (barcode: string | null) => {
    if (!barcode) {
        return Promise.resolve(null);
    }

    const { data } = await api.get(`/pantry/products/barcode/${barcode}/`);
    return data;
}

export default fetchProductByBarcode;
