import api from 'services/api';
import { Brand } from 'types';

const fetchBrands = async () => {
    const url = '/pantry/brands/all/';

    const { data } = await api.get<Brand[]>(url);
    return data;
}

export default fetchBrands;
