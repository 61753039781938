import dashboard from './dashboard';
import staff from './staff';
import pantry from './pantry';
import print from './print';
import pets from './pets';

const menuItems = {
  items: [dashboard, print, pantry, staff, pets]
};

export default menuItems;
