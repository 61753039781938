import {
    IconBeer,
    IconListDetails,
    IconFridge,
    IconBookmarks,
    IconBarcode,
    IconReceipt,
} from "@tabler/icons-react";

const pantry = {
    id: "PANTRY",
    title: "Despensa",
    type: "group",
    children: [
        {
            id: "products",
            title: "Produtos",
            type: "item",
            url: "/products",
            icon: IconBarcode,
            breadcrumbs: false,
        },
        {
            id: "receipts",
            title: "Recibos de compras",
            type: "item",
            url: "/receipts",
            icon: IconReceipt,
            breadcrumbs: false,
        },
        {
            id: "beers",
            title: "Cervejas",
            type: "item",
            url: "/beers",
            icon: IconBeer,
            breadcrumbs: false,
        },
        {
            id: "brands",
            title: "Marcas",
            type: "item",
            url: "/brands",
            icon: IconBookmarks,
            breadcrumbs: true,
        },
        {
            id: "beer-styles",
            title: "Estilos de cerveja",
            type: "item",
            url: "/beer-styles",
            icon: IconListDetails,
            breadcrumbs: true,
        },
        {
            id: 'storages',
            title: 'Armazenamentos',
            type: 'item',
            url: '/storages',
            icon: IconFridge,
            breadcrumbs: false
        },
        // {
        //     id: 'groceries',
        //     title: 'Mantimentos',
        //     type: 'item',
        //     url: '/groceries',
        //     icon: IconShoppingBag,
        //     breadcrumbs: false
        // },
    ],
};

export default pantry;
