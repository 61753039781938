import { useQuery } from '@tanstack/react-query';

import fetchBeerStylesAll from './request';

import { BeerStyle } from 'types';

const usePantryBeerStylesAll = () => {
    return useQuery<BeerStyle[]>({
        queryKey: ['usePantryBeerStylesAll'],
        queryFn: fetchBeerStylesAll,
        placeholderData: [],
        retryOnMount: true,
    });
}

export default usePantryBeerStylesAll;
