import api from 'services/api';
import { BeerStock } from 'types';

const fetchSingleBeerStockList = async (beer: string) => {
    const params = { beer };
    const url = '/pantry/beers/stocks/';

    const { data } = await api.get<BeerStock[]>(url, { params });
    return data;
}

export { fetchSingleBeerStockList }
