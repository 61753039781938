import { useQuery } from '@tanstack/react-query';

import api from 'services/api';
import { UserModules } from 'types';

const fetchUserModules = async () => {
    const url = '/v1/users/modules';
    const { data } = await api.get<UserModules>(url);
    return data;
}

const useUserModules = () => {
    return useQuery({
        queryKey: ['useUserModules'],
        queryFn: fetchUserModules,
        refetchOnMount: true,
        refetchOnWindowFocus: 'always',
    });
};

export default useUserModules;
