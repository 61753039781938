import { useQuery } from '@tanstack/react-query';

import fetchProductByBarcode from './request';

const usePantryProductBarcode = (barcode: string | null) => {
    return useQuery({
        queryKey: ['usePantryProductBarcode', barcode],
        queryFn: () => fetchProductByBarcode(barcode),
        placeholderData: null,
    });
};

export default usePantryProductBarcode;
