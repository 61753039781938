import { useQuery } from '@tanstack/react-query';

import api from 'services/api';
import { Country } from 'types';

const fetchCountries = async () => {
    const { data } = await api.get<Country[]>('/core/countries/');
    return data;
}

const useCountries = () => {
    return useQuery({
        queryKey: ['useCountries'],
        queryFn: fetchCountries,
        placeholderData: [],
    });
};

export default useCountries;
