import { useQuery } from '@tanstack/react-query';

import fetchBeerDetails from './request';

const useBeerDetails = (id: string | undefined) => {
    return useQuery({
        queryKey: ['useBeerDetails', id],
        queryFn: () => fetchBeerDetails(id),
        placeholderData: null,
    });
};

export default useBeerDetails;
