import axios from 'axios';

import config from 'common/config';
import { getItem } from 'common/auth';

const api = axios.create({
    baseURL: config.apiURL,
    headers: { 'Content-Type': 'application/json' },
});

api.interceptors.request.use((cfg) => {
    const auth = getItem('auth');

    if (auth?.token) {
        cfg.headers.Authorization = `Token ${auth.token}`;
    }

    return cfg;
});
// api.interceptors.response.use(
//     (response) => response,
//     (error) => {
//         const shouldIgnore401Error = [
//             'rest-auth/login/',
//         ].includes(error.config.url);
//         // Checks if the error out was from axios.
//         if (axios.isAxiosError(error) && error.response?.status === 401) {
//             // Logout on auth failure.
//             if (!shouldIgnore401Error) {
//                 window.localStorage.removeItem('auth');
//                 window.location.href = config.frontendURL ?? '/';
//             }
//             return;
//         }
//         return Promise.reject(error);
//     }
// );

export default api;
