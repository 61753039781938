import api from 'services/api';

const fetchPantryReceiptItems = async (id: string | undefined) => {
    if (!id) return Promise.resolve(null);

    const { data } = await api.get(`/pantry/receipts/${id}/items/`);
    return data;
}

export default fetchPantryReceiptItems;
