import api from 'services/api';

import { BrandsParams, BrandsResponse } from './types';

const fetchBrands = async (page: number, searchTerm: string = '') => {
    const currentPage = page || 1;
    let params: BrandsParams = {
        page: currentPage,
    }

    if (searchTerm.length > 0) {
        params = {
            ...params,
            name: searchTerm,
        }
    }
    const url = '/pantry/brands/'
    const { data } = await api.get<BrandsResponse>(url, { params });
    return data;
}

export default fetchBrands;
