import { useQuery } from '@tanstack/react-query';

import fetchBeerConsumptionList from './request';
import { BeerConsumptionListParams } from './types';

const usePantryBeerConsumptionsList = (params: BeerConsumptionListParams) => {
    return useQuery({
        queryKey: ['usePantryBeerConsumptionsList', params],
        queryFn: () => fetchBeerConsumptionList(params),
        placeholderData: {
            "count": 0,
            "next": null,
            "previous": null,
            "results": [],
        },
        retryOnMount: true,
    });
};

export default usePantryBeerConsumptionsList;
