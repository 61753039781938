import { Auth } from "./types";

function getItem(key: string) {
    const value = localStorage.getItem(key);
    if (value) {
        return JSON.parse(value);
    }
    return null;
}

function setItem(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
}

function isAuthenticated() {
    const auth = getItem("auth");
    if (auth) {
        const { token } = auth as Auth;
        if (token) {
            return true;
        }
    }
    return false;
}

export { getItem, setItem, isAuthenticated };
