import { IconDatabaseCog } from '@tabler/icons-react';

import config from 'common/config';

const icons = { IconDatabaseCog };

const other = {
  id: 'staff',
  type: 'group',
  children: [
    {
      id: 'django-admin',
      title: 'Django Admin',
      type: 'item',
      url: `${config.apiURL}admin/`,
      icon: icons.IconDatabaseCog,
      external: true,
      target: true
    }
  ]
};

export default other;
