import { useQuery } from '@tanstack/react-query';

import fetchPets from './request';

const usePantryPets =  (page: number, searchTerm: string) => {
    return useQuery({
        queryKey: ['usePets', page, searchTerm],
        queryFn: () => fetchPets(page, searchTerm),
        placeholderData: {
            "count": 0,
            "next": null,
            "previous": null,
            "results": [],
        },
    });
};

export default usePantryPets;