import { lazy } from "react";

import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

const DashboardDefault = Loadable(
    lazy(() => import("views/dashboard/Default")),
);

const UtilsTypography = Loadable(
    lazy(() => import("views/utilities/Typography")),
);
const UtilsColor = Loadable(lazy(() => import("views/utilities/Color")));
const UtilsShadow = Loadable(lazy(() => import("views/utilities/Shadow")));
const UtilsMaterialIcons = Loadable(
    lazy(() => import("views/utilities/MaterialIcons")),
);
const UtilsTablerIcons = Loadable(
    lazy(() => import("views/utilities/TablerIcons")),
);
const DashboardPage = Loadable(lazy(() => import("apps/dashboard")));
const BeerStylesPage = Loadable(lazy(() => import("apps/beer-styles")));
const BeersPage = Loadable(lazy(() => import("apps/beers")));
const BeerDetailsPage = Loadable(lazy(() => import("apps/beer-details")));
const StoragesPage = Loadable(lazy(() => import("apps/storages")));
const BrandsPage = Loadable(lazy(() => import("apps/brands")));
const BrandDetailsPage = Loadable(lazy(() => import("apps/brand-details")));
const ProductsPage = Loadable(lazy(() => import("apps/products")));
const ReceiptsPage = Loadable(lazy(() => import("apps/receipts")));
const ReceiptDetailsPage = Loadable(lazy(() => import("apps/receipt-details")));

// 3D Print
const PrintProjects = Loadable(lazy(() => import("apps/print/projects")));
const PrintModels = Loadable(lazy(() => import("apps/print/models")));
const PrintPrinters = Loadable(lazy(() => import("apps/print/printers")));
const PrintFilaments = Loadable(lazy(() => import("apps/print/filaments")));

// Pets
const PetsPage = Loadable(lazy(() => import("apps/pets")));
const PetFoodJournal = Loadable(lazy(() => import("apps/pets-food-journal")));

// sample page routing
const SamplePage = Loadable(lazy(() => import("views/sample-page")));

const MainRoutes = {
    path: "/",
    element: <MainLayout />,
    children: [
        {
            path: "/",
            element: <DashboardDefault />,
        },
        {
            path: "/receipts/:id",
            element: <ReceiptDetailsPage />,
        },
        {
            path: "/receipts",
            element: <ReceiptsPage />,
        },
        {
            path: "/products",
            element: <ProductsPage />,
        },
        {
            path: "/brands/:id",
            element: <BrandDetailsPage />,
        },
        {
            path: "/brands",
            element: <BrandsPage />,
        },
        {
            path: "/beer-styles",
            element: <BeerStylesPage />,
        },
        {
            path: "/beers",
            element: <BeersPage />,
        },
        {
            path: "/beers/:id",
            element: <BeerDetailsPage />,
        },
        {
            path: "/storages",
            element: <StoragesPage />,
        },
        // 3D Print
        {
            path: "/print/projects",
            element: <PrintProjects />,
        },
        {
            path: "/print/models",
            element: <PrintModels />,
        },
        {
            path: "/print/printers",
            element: <PrintPrinters />,
        },
        {
            path: "/print/filaments",
            element: <PrintFilaments />,
        },
        {
            path: "dashboard",
            children: [
                {
                    path: "default",
                    element: <DashboardDefault />,
                },
            ],
        },
        {
            path: "pets",
            element: <PetsPage />,
        },
        {
            path: "/pets/food/journal",
            element: <PetFoodJournal />,
        },
        {
            path: "pantry",
            children: [
                {
                    path: "default",
                    element: <DashboardPage />,
                },
            ],
        },
        // demo designs
        {
            path: "design",
            children: [
                {
                    path: "default",
                    element: <DashboardDefault />,
                },
            ],
        },
        {
            path: "utils",
            children: [
                {
                    path: "util-typography",
                    element: <UtilsTypography />,
                },
            ],
        },
        {
            path: "utils",
            children: [
                {
                    path: "util-color",
                    element: <UtilsColor />,
                },
            ],
        },
        {
            path: "utils",
            children: [
                {
                    path: "util-shadow",
                    element: <UtilsShadow />,
                },
            ],
        },
        {
            path: "icons",
            children: [
                {
                    path: "tabler-icons-react",
                    element: <UtilsTablerIcons />,
                },
            ],
        },
        {
            path: "icons",
            children: [
                {
                    path: "material-icons",
                    element: <UtilsMaterialIcons />,
                },
            ],
        },
        {
            path: "sample-page",
            element: <SamplePage />,
        },
    ],
};

export default MainRoutes;
