import { useQuery } from '@tanstack/react-query';

import fetchPantryBeers from './request';

const usePantryBeersList = (page: number, searchTerm: string) => {
    return useQuery({
        queryKey: ['usePantryBeersList', page, searchTerm],
        queryFn: () => fetchPantryBeers(page, searchTerm),
        placeholderData: {
            "count": 0,
            "next": null,
            "previous": null,
            "results": [],
        },
    });
};

export default usePantryBeersList;
