import api from 'services/api';

import { BeersListParams } from './types';

const fetchPantryBeers = async (page: number, search: string = '') => {
    const currentPage = page || 1;
    let params: BeersListParams = {
        page: currentPage,
    }

    if (search.length > 0) {
        params = {
            ...params,
            search,
        }
    }
    const { data } = await api.get('/pantry/beers/', { params });
    return data;
}

export default fetchPantryBeers;