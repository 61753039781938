import { useQuery } from '@tanstack/react-query';

import fetchBeerContainerTypes from './request';

const usePantryBeerContainerTypes = () => {
    return useQuery({
        queryKey: ['usePantryBeerContainerTypes'],
        queryFn: fetchBeerContainerTypes,
        placeholderData: [],
    });
};

export default usePantryBeerContainerTypes;
