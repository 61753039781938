import { useQuery } from '@tanstack/react-query';

import fetchBrands from './request';

const usePantryBrands =  (page: number, searchTerm: string) => {
    return useQuery({
        queryKey: ['usePantryBrands', page, searchTerm],
        queryFn: () => fetchBrands(page, searchTerm),
        placeholderData: {
            "count": 0,
            "next": null,
            "previous": null,
            "results": [],
        },
    });
};

export default usePantryBrands;