import { useQuery } from '@tanstack/react-query';

import fetchPantryReceiptDetails from './request';

const usePantryReceiptDetails = (id: string | undefined) => {
    return useQuery({
        queryKey: ['usePantryReceiptDetails', id],
        queryFn: () => fetchPantryReceiptDetails(id),
        placeholderData: null,
    });
};

export default usePantryReceiptDetails;
