import { Typography } from '@mui/material';

import NavGroup from './NavGroup';
import menuItem from 'menu-items';

import { useUserModules } from 'services/queries';

export default function MenuList() {
    const { data: modules } = useUserModules();

    if (!modules) {
        return null;
    }
    const navItems = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                if (Object.keys(modules).includes(item.id.toUpperCase())) {
                    return <NavGroup key={item.id} item={item} />;
                }
                return null;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};
