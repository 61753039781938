import { useQuery } from '@tanstack/react-query';

import fetchPantryProducts from './request';

const usePantryProductsList = (page: number, searchTerm: string) => {
    return useQuery({
        queryKey: ['usePantryProductsList', page, searchTerm],
        queryFn: () => fetchPantryProducts(page, searchTerm),
        placeholderData: {
            "count": 0,
            "next": null,
            "previous": null,
            "results": [],
        },
    });
};

export default usePantryProductsList;
