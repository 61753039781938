import { useQuery } from '@tanstack/react-query';

import api from 'services/api';

interface BeerStylesParams {
    name?: string;
    page: number;
}

const fetchPantryBeerStyles = async (page: number, searchTerm: string = '') => {
    const currentPage = page || 1;
    let params: BeerStylesParams = {
        page: currentPage,
    }

    if (searchTerm.length > 0) {
        params = {
            ...params,
            name: searchTerm,
        }
    }
    const { data } = await api.get('/pantry/beers/styles/', { params });
    return data;
}

const usePantryBeerStyles = (page: number, searchTerm: string) => {
    return useQuery({
        queryKey: ['usePantryBeerStyles', page, searchTerm],
        queryFn: () => fetchPantryBeerStyles(page, searchTerm),
        placeholderData: {
            "count": 0,
            "next": null,
            "previous": null,
            "results": [],
        },
    });
};

export default usePantryBeerStyles;
