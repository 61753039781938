import {
    IconBookmarks,
} from "@tabler/icons-react";

const print = {
    id: "PRINT",
    title: "Impressão 3D",
    type: "group",
    children: [
        {
            id: "projects",
            title: "Projetos",
            type: "item",
            url: "/print/projects",
            icon: IconBookmarks,
            breadcrumbs: false,
        },
        {
            id: "models",
            title: "Modelos",
            type: "item",
            url: "/print/models",
            icon: IconBookmarks,
            breadcrumbs: false,
        },
        {
            id: "printers",
            title: "Impressoras",
            type: "item",
            url: "/print/printers",
            icon: IconBookmarks,
            breadcrumbs: false,
        },
        {
            id: "filaments",
            title: "Filamentos",
            type: "item",
            url: "/print/filaments",
            icon: IconBookmarks,
            breadcrumbs: false,
        },
    ],
};

export default print;
