import { useQuery } from '@tanstack/react-query';

import fetchBrandDetails from './request';

const usePantryBrandDetails = (id: string | undefined) => {
    return useQuery({
        queryKey: ['usePantryBrandDetails', id],
        queryFn: () => fetchBrandDetails(id),
        placeholderData: null,
    });
};

export default usePantryBrandDetails;
