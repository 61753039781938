import api from 'services/api';
import { BeerConsumptionListApiResponse } from 'types';
import { BeerConsumptionListParams } from './types';

const fetchBeerConsumptionList = async (params: BeerConsumptionListParams) => {
    const url = '/pantry/beers/consumptions/';

    const { data } = await api.get<BeerConsumptionListApiResponse>(url, { params });
    return data;
}

export default fetchBeerConsumptionList;
